import 'src/global.css';
import 'src/loader.css';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import 'react-toastify/dist/ReactToastify.css';
import { useContext, useEffect } from 'react';
import appContext from './Context/AppContext';
import Loader from './utils/loader';


// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();
  const AppContext = useContext(appContext);
  useEffect(() => { }, [
    AppContext?.isLoggedIn
  ])
  return (

    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}
